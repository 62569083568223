import { IconComponent } from '@amaris/lib-highway/components/icon';
import { TranslatePipe } from '@amaris/lib-highway/pipes';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rounded-button',
  standalone: true,
  imports: [CommonModule, IconComponent, TranslatePipe],
  templateUrl: './rounded-button.component.html',
  styleUrl: './rounded-button.component.scss',
})
export class RoundedButtonComponent {
  @Input() icon: string = null;
  @Input() label: string = 'Button Label';
  @Input() disabled: boolean = false;
  @Input() submit: boolean = false;
  @Input() inverted: boolean = false;
}
